<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 162.3 85.7"
    enable-background="new 0 0 162.3 85.7"
    xml:space="preserve"
  >
    <g id="side_circles">
      <linearGradient
        id="SVGID_3_"
        gradientUnits="userSpaceOnUse"
        x1="0"
        y1="42.874"
        x2="85.739"
        y2="42.874"
      >
        <stop
          offset="0"
          :style="`stop-color:${strokeStart}`"
        />
        <stop
          offset="1"
          :style="`stop-color:${strokeStop}`"
        />
      </linearGradient>
      <path
        fill="none"
        stroke="url(#SVGID_3_)"
        stroke-width="0.7"
        stroke-miterlimit="10"
        d="M85.4,42.9c0-23.5-19-42.5-42.5-42.5v0c-23.5,0-42.5,19-42.5,42.5h0c0,23.5,19,42.5,42.5,42.5v0C66.3,85.4,85.4,66.4,85.4,42.9L85.4,42.9z"
      />
      <linearGradient
        id="SVGID_4_"
        gradientUnits="userSpaceOnUse"
        x1="8.5039"
        y1="42.874"
        x2="94.2429"
        y2="42.874"
      >
        <stop
          offset="0"
          :style="`stop-color:${strokeStart}`"
        />
        <stop
          offset="1"
          :style="`stop-color:${strokeStop}`"
        />
      </linearGradient>
      <path
        fill="none"
        stroke="url(#SVGID_4_)"
        stroke-width="0.7"
        stroke-miterlimit="10"
        d="M93.9,42.9c0-23.5-19-42.5-42.5-42.5v0c-23.5,0-42.5,19-42.5,42.5h0c0,23.5,19,42.5,42.5,42.5v0C74.8,85.4,93.9,66.4,93.9,42.9L93.9,42.9z"
      />
      <linearGradient
        id="SVGID_5_"
        gradientUnits="userSpaceOnUse"
        x1="17.0078"
        y1="42.874"
        x2="102.7468"
        y2="42.874"
      >
        <stop
          offset="0"
          :style="`stop-color:${strokeStart}`"
        />
        <stop
          offset="1"
          :style="`stop-color:${strokeStop}`"
        />
      </linearGradient>
      <path
        fill="none"
        stroke="url(#SVGID_5_)"
        stroke-width="0.7"
        stroke-miterlimit="10"
        d="M102.4,42.9c0-23.5-19-42.5-42.5-42.5v0c-23.5,0-42.5,19-42.5,42.5h0c0,23.5,19,42.5,42.5,42.5v0C83.3,85.4,102.4,66.4,102.4,42.9L102.4,42.9z"
      />
      <linearGradient
        id="SVGID_6_"
        gradientUnits="userSpaceOnUse"
        x1="25.5118"
        y1="42.874"
        x2="111.2508"
        y2="42.874"
      >
        <stop
          offset="0"
          :style="`stop-color:${strokeStart}`"
        />
        <stop
          offset="1"
          :style="`stop-color:${strokeStop}`"
        />
      </linearGradient>
      <path
        fill="none"
        stroke="url(#SVGID_6_)"
        stroke-width="0.7"
        stroke-miterlimit="10"
        d="M110.9,42.9c0-23.5-19-42.5-42.5-42.5v0c-23.5,0-42.5,19-42.5,42.5h0c0,23.5,19,42.5,42.5,42.5v0C91.9,85.4,110.9,66.4,110.9,42.9L110.9,42.9z"
      />
      <linearGradient
        id="SVGID_7_"
        gradientUnits="userSpaceOnUse"
        x1="34.0157"
        y1="42.874"
        x2="119.7547"
        y2="42.874"
      >
        <stop
          offset="0"
          :style="`stop-color:${strokeStart}`"
        />
        <stop
          offset="1"
          :style="`stop-color:${strokeStop}`"
        />
      </linearGradient>
      <path
        fill="none"
        stroke="url(#SVGID_7_)"
        stroke-width="0.7"
        stroke-miterlimit="10"
        d="M119.4,42.9c0-23.5-19-42.5-42.5-42.5v0c-23.5,0-42.5,19-42.5,42.5h0c0,23.5,19,42.5,42.5,42.5v0C100.4,85.4,119.4,66.4,119.4,42.9L119.4,42.9z"
      />
      <linearGradient
        id="SVGID_8_"
        gradientUnits="userSpaceOnUse"
        x1="42.5197"
        y1="42.874"
        x2="128.2587"
        y2="42.874"
      >
        <stop
          offset="0"
          :style="`stop-color:${strokeStart}`"
        />
        <stop
          offset="1"
          :style="`stop-color:${strokeStop}`"
        />
      </linearGradient>
      <path
        fill="none"
        stroke="url(#SVGID_8_)"
        stroke-width="0.7"
        stroke-miterlimit="10"
        d="M127.9,42.9c0-23.5-19-42.5-42.5-42.5v0c-23.5,0-42.5,19-42.5,42.5h0c0,23.5,19,42.5,42.5,42.5v0C108.9,85.4,127.9,66.4,127.9,42.9L127.9,42.9z"
      />
      <linearGradient
        id="SVGID_9_"
        gradientUnits="userSpaceOnUse"
        x1="51.0236"
        y1="42.874"
        x2="136.7626"
        y2="42.874"
      >
        <stop
          offset="0"
          :style="`stop-color:${strokeStart}`"
        />
        <stop
          offset="1"
          :style="`stop-color:${strokeStop}`"
        />
      </linearGradient>
      <path
        fill="none"
        stroke="url(#SVGID_9_)"
        stroke-width="0.7"
        stroke-miterlimit="10"
        d="M136.4,42.9c0-23.5-19-42.5-42.5-42.5v0c-23.5,0-42.5,19-42.5,42.5h0c0,23.5,19,42.5,42.5,42.5v0C117.4,85.4,136.4,66.4,136.4,42.9L136.4,42.9z"
      />
      <linearGradient
        id="SVGID_10_"
        gradientUnits="userSpaceOnUse"
        x1="59.5275"
        y1="42.874"
        x2="145.2665"
        y2="42.874"
      >
        <stop
          offset="0"
          :style="`stop-color:${strokeStart}`"
        />
        <stop
          offset="1"
          :style="`stop-color:${strokeStop}`"
        />
      </linearGradient>
      <path
        fill="none"
        stroke="url(#SVGID_10_)"
        stroke-width="0.7"
        stroke-miterlimit="10"
        d="M144.9,42.9c0-23.5-19-42.5-42.5-42.5v0c-23.5,0-42.5,19-42.5,42.5h0c0,23.5,19,42.5,42.5,42.5v0C125.9,85.4,144.9,66.4,144.9,42.9L144.9,42.9z"
      />
      <linearGradient
        id="SVGID_11_"
        gradientUnits="userSpaceOnUse"
        x1="68.0315"
        y1="42.874"
        x2="153.7705"
        y2="42.874"
      >
        <stop
          offset="0"
          :style="`stop-color:${strokeStart}`"
        />
        <stop
          offset="1"
          :style="`stop-color:${strokeStop}`"
        />
      </linearGradient>
      <path
        fill="none"
        stroke="url(#SVGID_11_)"
        stroke-width="0.7"
        stroke-miterlimit="10"
        d="M153.4,42.9c0-23.5-19-42.5-42.5-42.5v0c-23.5,0-42.5,19-42.5,42.5h0c0,23.5,19,42.5,42.5,42.5v0C134.4,85.4,153.4,66.4,153.4,42.9L153.4,42.9z"
      />
      <linearGradient
        id="SVGID_12_"
        gradientUnits="userSpaceOnUse"
        x1="76.5354"
        y1="42.874"
        x2="162.2744"
        y2="42.874"
      >
        <stop
          offset="0"
          :style="`stop-color:${strokeStart}`"
        />
        <stop
          offset="1"
          :style="`stop-color:${strokeStop}`"
        />
      </linearGradient>
      <path
        fill="none"
        stroke="url(#SVGID_12_)"
        stroke-width="0.7"
        stroke-miterlimit="10"
        d="M161.9,42.9c0-23.5-19-42.5-42.5-42.5v0c-23.5,0-42.5,19-42.5,42.5h0c0,23.5,19,42.5,42.5,42.5v0C142.9,85.4,161.9,66.4,161.9,42.9L161.9,42.9z"
      />
    </g>
  </svg>
</template>

<script>
import svg from '@/mixins/svg'
export default {
  mixins: [svg]
}
</script>
