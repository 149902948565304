<template>
  <div>
    <SideCircles
      class="banner-design-elements"
      width="300px"
      style="right: 80px; top:-20px; opacity: 0.4"
      fill
    />
  </div>
</template>

<script>
import SideCircles from '@/components/design-elements/sideCircles.vue'

export default {
  components: {
    SideCircles
  }
}
</script>
